:root, body {
	height: 100%;
	margin: 0;
}

body {
	color: var(--default-text-color);
	font-family: var(--app-font);
	font-weight: var(--weight-normal);
	font-size: 14px;
	line-height: 1.5;
	text-align: left;

	&:is(.tablet, .desktop) {
		font-size: 16px;
	}
}

* {
	box-sizing: border-box;
}


h1, h2, h3, h4, h5, h6 {
	position: relative;
	font-weight: var(--weight-normal);
	line-height: 1.2;
	margin-top: 0;
	margin-bottom: 1rem;
}

h1 {
	font-size: 3rem;
	text-decoration: underline;
	margin-top: 2.5rem;
}
h2 {
	font-size: 2rem;
	text-decoration: underline;
	margin-top: 2.5rem;
}
h3 {
	font-size: 1.75rem;
	margin-top: 1.5rem;
}
h4 {
	font-size: 1.5rem;
}
h5 {
	font-size: 1.25rem;
}
h6 {
	font-size: 1rem;
}

p {
	margin-top: 0;
	margin-bottom: 1rem;
}

b, strong {
	font-weight: var(--weight-semibold);
}

pre code {
	display: block;
	padding: 0.5rem;
	overflow: auto hidden;
}

code {
	display: inline-block;
	font-family: var(--monospace-font);
	font-weight: var(--weight-medium);
	background-color: var(--accent-background-color);
	border-radius: 4px;
	padding: 0 0.5rem;
}

img {
	max-width: 100%;
}

img + em {
	display: block;
	text-align: center;
}


a {
	cursor: pointer;
	text-decoration: none;
	width: max-content;

	&:not(.mat-mdc-icon-button) {
		color: var(--default-theme-color);

		&:hover {
			color: var(--darkest-theme-color);
			text-decoration: underline;
		}
	}
}
