:root {
	--icon-size: 24px;
	--icon-optical-size: 24;
	--icon-fill: 0;
	--icon-weight: 300;
}

mat-icon.mat-icon {
	fill: currentColor;
	width: var(--icon-size);
	height: var(--icon-size);
	font-size: var(--icon-size);
	line-height: 1em;
	text-align: center;

	font-variation-settings:
		'FILL' var(--icon-fill),
		'wght' var(--icon-weight),
		'GRAD' 0,
		'opsz' var(--icon-optical-size);

	&.fill {
		--icon-fill: 1;
	}

	> svg {
		width: auto;
		max-width: 100%;

		> path {
			fill: currentColor;
		}
	}
}
