body {
	&:is(.tablet, .desktop) {
		--navigation-height: var(--mat-toolbar-standard-height);
	}

	&.mobile {
		--navigation-height: var(--mat-toolbar-mobile-height);
	}
}

mat-toolbar.mat-toolbar {
	height: var(--navigation-height);
	background-color: var(--default-background-color);
	color: var(--default-text-color);
}
