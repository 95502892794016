/* EB Garamond Regular */
@font-face {
	font-family: 'EB Garamond';
	src: url('../../assets/fonts/eb-garamond/EBGaramond-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	size-adjust: 110%;
}

/* EB Garamond Regular Italic */
@font-face {
	font-family: 'EB Garamond';
	src: url('../../assets/fonts/eb-garamond/EBGaramond-RegularItalic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
	font-display: swap;
	size-adjust: 110%;
}

/* EB Garamond Medium */
@font-face {
	font-family: 'EB Garamond';
	src: url('../../assets/fonts/eb-garamond/EBGaramond-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

/* EB Garamond Medium Italic */
@font-face {
	font-family: 'EB Garamond';
	src: url('../../assets/fonts/eb-garamond/EBGaramond-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
	size-adjust: 110%;
}

/* EB Garamond SemiBold */
@font-face {
	font-family: 'EB Garamond';
	src: url('../../assets/fonts/eb-garamond/EBGaramond-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
	size-adjust: 110%;
}

/* EB Garamond SemiBold Italic */
@font-face {
	font-family: 'EB Garamond';
	src: url('../../assets/fonts/eb-garamond/EBGaramond-SemiBoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
	font-display: swap;
	size-adjust: 110%;
}

/* EB Garamond Bold */
@font-face {
	font-family: 'EB Garamond';
	src: url('../../assets/fonts/eb-garamond/EBGaramond-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
	size-adjust: 110%;
}

/* EB Garamond Bold Italic */
@font-face {
	font-family: 'EB Garamond';
	src: url('../../assets/fonts/eb-garamond/EBGaramond-BoldItalic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
	font-display: swap;
	size-adjust: 110%;
}
