/* Lato Hairline */
@font-face {
    font-family: 'Lato';
    src: url('../../assets/fonts/lato/LatoLatin-Hairline.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

/* Lato Hairline Italic */
@font-face {
    font-family: 'Lato';
    src: url('../../assets/fonts/lato/LatoLatin-HairlineItalic.woff2') format('woff2');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

/* Lato Thin */
@font-face {
    font-family: 'Lato';
    src: url('../../assets/fonts/lato/LatoLatin-Thin.woff2') format('woff2');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

/* Lato Thin Italic */
@font-face {
    font-family: 'Lato';
    src: url('../../assets/fonts/lato/LatoLatin-ThinItalic.woff2') format('woff2');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

/* Lato Light */
@font-face {
    font-family: 'Lato';
    src: url('../../assets/fonts/lato/LatoLatin-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

/* Lato Light Italic */
@font-face {
    font-family: 'Lato';
    src: url('../../assets/fonts/lato/LatoLatin-LightItalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

/* Lato Regular */
@font-face {
    font-family: 'Lato';
    src: url('../../assets/fonts/lato/LatoLatin-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

/* Lato Regular Italic */
@font-face {
    font-family: 'Lato';
    src: url('../../assets/fonts/lato/LatoLatin-RegularItalic.woff2') format('woff2');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

/* Lato Medium */
@font-face {
    font-family: 'Lato';
    src: url('../../assets/fonts/lato/LatoLatin-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

/* Lato Medium Italic */
@font-face {
    font-family: 'Lato';
    src: url('../../assets/fonts/lato/LatoLatin-MediumItalic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

/* Lato SemiBold */
@font-face {
    font-family: 'Lato';
    src: url('../../assets/fonts/lato/LatoLatin-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

/* Lato SemiBold Italic */
@font-face {
    font-family: 'Lato';
    src: url('../../assets/fonts/lato/LatoLatin-SemiBoldItalic.woff2') format('woff2');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

/* Lato Bold */
@font-face {
    font-family: 'Lato';
    src: url('../../assets/fonts/lato/LatoLatin-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

/* Lato Bold Italic */
@font-face {
    font-family: 'Lato';
    src: url('../../assets/fonts/lato/LatoLatin-BoldItalic.woff2') format('woff2');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}
