/* Victor Mono Thin */
@font-face {
    font-family: 'Victor Mono';
    src: url('../../assets/fonts/victor-mono/VictorMono-Thin.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

/* Victor Mono Thin Italic */
@font-face {
    font-family: 'Victor Mono';
    src: url('../../assets/fonts/victor-mono/VictorMono-ThinItalic.woff2') format('woff2');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

/* Victor Mono Extra Light */
@font-face {
    font-family: 'Victor Mono';
    src: url('../../assets/fonts/victor-mono/VictorMono-ExtraLight.woff2') format('woff2');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

/* Victor Mono Extra Light Italic */
@font-face {
    font-family: 'Victor Mono';
    src: url('../../assets/fonts/victor-mono/VictorMono-ExtraLightItalic.woff2') format('woff2');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

/* Victor Mono Light */
@font-face {
    font-family: 'Victor Mono';
    src: url('../../assets/fonts/victor-mono/VictorMono-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

/* Victor Mono Light Italic */
@font-face {
    font-family: 'Victor Mono';
    src: url('../../assets/fonts/victor-mono/VictorMono-LightItalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

/* Victor Mono Regular */
@font-face {
    font-family: 'Victor Mono';
    src: url('../../assets/fonts/victor-mono/VictorMono-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

/* Victor Mono Regular Italic */
@font-face {
    font-family: 'Victor Mono';
    src: url('../../assets/fonts/victor-mono/VictorMono-RegularItalic.woff2') format('woff2');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

/* Victor Mono Medium */
@font-face {
    font-family: 'Victor Mono';
    src: url('../../assets/fonts/victor-mono/VictorMono-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

/* Victor Mono Medium Italic */
@font-face {
    font-family: 'Victor Mono';
    src: url('../../assets/fonts/victor-mono/VictorMono-MediumItalic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

/* Victor Mono SemiBold */
@font-face {
    font-family: 'Victor Mono';
    src: url('../../assets/fonts/victor-mono/VictorMono-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

/* Victor Mono SemiBold Italic */
@font-face {
    font-family: 'Victor Mono';
    src: url('../../assets/fonts/victor-mono/VictorMono-SemiBoldItalic.woff2') format('woff2');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

/* Victor Mono Bold */
@font-face {
    font-family: 'Victor Mono';
    src: url('../../assets/fonts/victor-mono/VictorMono-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

/* Victor Mono Bold Italic */
@font-face {
    font-family: 'Victor Mono';
    src: url('../../assets/fonts/victor-mono/VictorMono-BoldItalic.woff2') format('woff2');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}
