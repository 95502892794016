.mat-mdc-menu-panel .mat-mdc-menu-item {
	color: var(--default-text-color);

	.mat-icon {
		color: var(--default-text-color);
	}
}

.light-theme .mat-mdc-menu-panel {
	background-color: var(--default-background-color);
}

.dark-theme .mat-mdc-menu-panel {
	background-color: var(--accent-background-color);
}
