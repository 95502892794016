/**
 * Custom Theming for Angular Material
 * For more information: https://material.angular.io/guide/theming
 */
@use '@angular/material' as mat;
@use './variables/color-theme' as color-theme;
@use './variables/typography' as typography;
@use './variables/material-config';

/**
 * Includes the common styles for Angular Material.
 * Be sure that you only ever include this mixin once!
 */
@include mat.core();

body {
	&.light-theme {
		@include mat.all-component-themes(color-theme.$light-theme);
	}

	&.dark-theme {
		@include mat.all-component-themes(color-theme.$dark-theme);
	}

	&:is(.light-theme, .dark-theme) {
		@include mat.all-component-typographies(typography.$typography);
	}
}
