.mat-mdc-card {
	.mat-mdc-card-avatar {
		border-radius: unset;
		--icon-size: 40px;
	}

	.mat-mdc-card-header-text {
		margin-bottom: 16px;
		display: flex;
		flex-direction: column;
	}

	.mat-mdc-card-title {
		line-height: 24px;
		font-variant: small-caps;
	}

	.mat-mdc-card-subtitle {
		font-variant: small-caps;
	}
}

.light-theme .mat-mdc-card {
	background-color: var(--default-background-color);
	color: var(--default-text-color);
}

.dark-theme .mat-mdc-card {
	background-color: var(--accent-background-color);
	color: var(--default-text-color);
}
